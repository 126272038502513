import { default as index4VgFaGvz2KMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/index.vue?macro=true";
import { default as challengesvBEdhJgBtLMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/viennazuckerl/pages/me/challenges.vue?macro=true";
import { default as faqq7DagN01IhMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/me/faq.vue?macro=true";
import { default as friendskJvyeyHsuEMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/me/friends.vue?macro=true";
import { default as leaderboardJE3HB3EThCMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/me/leaderboard.vue?macro=true";
import { default as moreFriends0Y3yCTNSYzMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/me/moreFriends.vue?macro=true";
import { default as openJUfFnYFvQDMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/me/open.vue?macro=true";
import { default as overviewYefe5AmMTSMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/me/overview.vue?macro=true";
import { default as _91id_93iwSqtqJC39Meta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/me/posts/[id].vue?macro=true";
import { default as avatarCQj4mVa7h3Meta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/me/profile/avatar.vue?macro=true";
import { default as indexnIrJ38qxh5Meta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/me/profile/index.vue?macro=true";
import { default as rewardswUYrkFRaGxMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/me/profile/rewards.vue?macro=true";
import { default as _91id_93m9BQqUtHc3Meta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/me/rewards/detail/[id].vue?macro=true";
import { default as _91id_93nq9ziIVOpJMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/me/rewards/detail/coupon/[id].vue?macro=true";
import { default as _91id_932wvjWJigDpMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/me/rewards/detail/custom/[id].vue?macro=true";
import { default as index2u6zHyMQTvMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/me/rewards/index.vue?macro=true";
import { default as vplustr33lOuUnRMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/me/rewards/vplus.vue?macro=true";
import { default as _91slug_93cALKLZbnMwMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/me/special/[slug].vue?macro=true";
import { default as _91id_93NBgcJWx7DOMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/me/voucher/[id].vue?macro=true";
import { default as indexFDeKOvF6WnMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/me/voucher/index.vue?macro=true";
import { default as badge40U9cXocZ6Meta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/notification/badge.vue?macro=true";
import { default as birthdayWVHuINs5IoMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/viennazuckerl/pages/notification/birthday.vue?macro=true";
import { default as birthdayweekrVTDctBKjjMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/viennazuckerl/pages/notification/birthdayweek.vue?macro=true";
import { default as christmasjxQt4plJyAMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/viennazuckerl/pages/notification/christmas.vue?macro=true";
import { default as _91id_93fnPmr5Qy7iMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/notification/detail/[id].vue?macro=true";
import { default as levelupK5DNuwhbYxMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/notification/levelup.vue?macro=true";
import { default as profileRqRR8giDguMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/notification/profile.vue?macro=true";
import { default as registerQdECHfPoBAMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/notification/register.vue?macro=true";
import { default as specialday8ttKaMLvxcMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/notification/specialday.vue?macro=true";
import { default as openhAQE9JNUr5Meta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/viennazuckerl/pages/open.vue?macro=true";
import { default as _91id_93CZGER3ORbhMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/preview/[type]/[id].vue?macro=true";
import { default as popups635NIisNNMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/preview/notifications/popup.vue?macro=true";
import { default as detail1S1wAgUjh4Meta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/preview/rewards/detail.vue?macro=true";
import { default as largecards7amSsDUKGMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/preview/rewards/largecard.vue?macro=true";
import { default as smallcardCQZ8SCq76cMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/preview/rewards/smallcard.vue?macro=true";
import { default as specialfbrrOlIuJZMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/preview/specials/special.vue?macro=true";
import { default as mainrt2YkBRtaJMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/widget/main.vue?macro=true";
import { default as index511YbLBD2XMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/widget/post/[id]/index.vue?macro=true";
import { default as prizewheelAxqEheSxjSMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/widget/prizewheel.vue?macro=true";
import { default as profilesFLEdE09kxMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/widget/profile.vue?macro=true";
import { default as quiz1tagG422sQMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/widget/quiz.vue?macro=true";
import { default as _91id_93MX8lNq7cRPMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/widget/reward/[id].vue?macro=true";
import { default as indexDlx2YgmdRpMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/widget/reward/index.vue?macro=true";
import { default as indexkhBsH2GzGwMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/widget/special/index.vue?macro=true";
import { default as simplepLY6eXLBuRMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/widget/special/simple.vue?macro=true";
import { default as uservNQ8WbQBxGMeta } from "/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/widget/user.vue?macro=true";
export default [
  {
    name: index4VgFaGvz2KMeta?.name ?? "index",
    path: index4VgFaGvz2KMeta?.path ?? "/",
    meta: index4VgFaGvz2KMeta || {},
    alias: index4VgFaGvz2KMeta?.alias || [],
    redirect: index4VgFaGvz2KMeta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/index.vue").then(m => m.default || m)
  },
  {
    name: challengesvBEdhJgBtLMeta?.name ?? "me-challenges",
    path: challengesvBEdhJgBtLMeta?.path ?? "/me/challenges",
    meta: challengesvBEdhJgBtLMeta || {},
    alias: challengesvBEdhJgBtLMeta?.alias || [],
    redirect: challengesvBEdhJgBtLMeta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/viennazuckerl/pages/me/challenges.vue").then(m => m.default || m)
  },
  {
    name: faqq7DagN01IhMeta?.name ?? "me-faq",
    path: faqq7DagN01IhMeta?.path ?? "/me/faq",
    meta: faqq7DagN01IhMeta || {},
    alias: faqq7DagN01IhMeta?.alias || [],
    redirect: faqq7DagN01IhMeta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/me/faq.vue").then(m => m.default || m)
  },
  {
    name: friendskJvyeyHsuEMeta?.name ?? "me-friends",
    path: friendskJvyeyHsuEMeta?.path ?? "/me/friends",
    meta: friendskJvyeyHsuEMeta || {},
    alias: friendskJvyeyHsuEMeta?.alias || [],
    redirect: friendskJvyeyHsuEMeta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/me/friends.vue").then(m => m.default || m)
  },
  {
    name: leaderboardJE3HB3EThCMeta?.name ?? "me-leaderboard",
    path: leaderboardJE3HB3EThCMeta?.path ?? "/me/leaderboard",
    meta: leaderboardJE3HB3EThCMeta || {},
    alias: leaderboardJE3HB3EThCMeta?.alias || [],
    redirect: leaderboardJE3HB3EThCMeta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/me/leaderboard.vue").then(m => m.default || m)
  },
  {
    name: moreFriends0Y3yCTNSYzMeta?.name ?? "me-moreFriends",
    path: moreFriends0Y3yCTNSYzMeta?.path ?? "/me/moreFriends",
    meta: moreFriends0Y3yCTNSYzMeta || {},
    alias: moreFriends0Y3yCTNSYzMeta?.alias || [],
    redirect: moreFriends0Y3yCTNSYzMeta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/me/moreFriends.vue").then(m => m.default || m)
  },
  {
    name: openJUfFnYFvQDMeta?.name ?? "me-open",
    path: openJUfFnYFvQDMeta?.path ?? "/me/open",
    meta: openJUfFnYFvQDMeta || {},
    alias: openJUfFnYFvQDMeta?.alias || [],
    redirect: openJUfFnYFvQDMeta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/me/open.vue").then(m => m.default || m)
  },
  {
    name: overviewYefe5AmMTSMeta?.name ?? "me-overview",
    path: overviewYefe5AmMTSMeta?.path ?? "/me/overview",
    meta: overviewYefe5AmMTSMeta || {},
    alias: overviewYefe5AmMTSMeta?.alias || [],
    redirect: overviewYefe5AmMTSMeta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/me/overview.vue").then(m => m.default || m)
  },
  {
    name: _91id_93iwSqtqJC39Meta?.name ?? "me-posts-id",
    path: _91id_93iwSqtqJC39Meta?.path ?? "/me/posts/:id()",
    meta: _91id_93iwSqtqJC39Meta || {},
    alias: _91id_93iwSqtqJC39Meta?.alias || [],
    redirect: _91id_93iwSqtqJC39Meta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/me/posts/[id].vue").then(m => m.default || m)
  },
  {
    name: avatarCQj4mVa7h3Meta?.name ?? "me-profile-avatar",
    path: avatarCQj4mVa7h3Meta?.path ?? "/me/profile/avatar",
    meta: avatarCQj4mVa7h3Meta || {},
    alias: avatarCQj4mVa7h3Meta?.alias || [],
    redirect: avatarCQj4mVa7h3Meta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/me/profile/avatar.vue").then(m => m.default || m)
  },
  {
    name: indexnIrJ38qxh5Meta?.name ?? "me-profile",
    path: indexnIrJ38qxh5Meta?.path ?? "/me/profile",
    meta: indexnIrJ38qxh5Meta || {},
    alias: indexnIrJ38qxh5Meta?.alias || [],
    redirect: indexnIrJ38qxh5Meta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/me/profile/index.vue").then(m => m.default || m)
  },
  {
    name: rewardswUYrkFRaGxMeta?.name ?? "me-profile-rewards",
    path: rewardswUYrkFRaGxMeta?.path ?? "/me/profile/rewards",
    meta: rewardswUYrkFRaGxMeta || {},
    alias: rewardswUYrkFRaGxMeta?.alias || [],
    redirect: rewardswUYrkFRaGxMeta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/me/profile/rewards.vue").then(m => m.default || m)
  },
  {
    name: _91id_93m9BQqUtHc3Meta?.name ?? "me-rewards-detail-id",
    path: _91id_93m9BQqUtHc3Meta?.path ?? "/me/rewards/detail/:id()",
    meta: _91id_93m9BQqUtHc3Meta || {},
    alias: _91id_93m9BQqUtHc3Meta?.alias || [],
    redirect: _91id_93m9BQqUtHc3Meta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/me/rewards/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93nq9ziIVOpJMeta?.name ?? "me-rewards-detail-coupon-id",
    path: _91id_93nq9ziIVOpJMeta?.path ?? "/me/rewards/detail/coupon/:id()",
    meta: _91id_93nq9ziIVOpJMeta || {},
    alias: _91id_93nq9ziIVOpJMeta?.alias || [],
    redirect: _91id_93nq9ziIVOpJMeta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/me/rewards/detail/coupon/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_932wvjWJigDpMeta?.name ?? "me-rewards-detail-custom-id",
    path: _91id_932wvjWJigDpMeta?.path ?? "/me/rewards/detail/custom/:id()",
    meta: _91id_932wvjWJigDpMeta || {},
    alias: _91id_932wvjWJigDpMeta?.alias || [],
    redirect: _91id_932wvjWJigDpMeta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/me/rewards/detail/custom/[id].vue").then(m => m.default || m)
  },
  {
    name: index2u6zHyMQTvMeta?.name ?? "me-rewards",
    path: index2u6zHyMQTvMeta?.path ?? "/me/rewards",
    meta: index2u6zHyMQTvMeta || {},
    alias: index2u6zHyMQTvMeta?.alias || [],
    redirect: index2u6zHyMQTvMeta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/me/rewards/index.vue").then(m => m.default || m)
  },
  {
    name: vplustr33lOuUnRMeta?.name ?? "me-rewards-vplus",
    path: vplustr33lOuUnRMeta?.path ?? "/me/rewards/vplus",
    meta: vplustr33lOuUnRMeta || {},
    alias: vplustr33lOuUnRMeta?.alias || [],
    redirect: vplustr33lOuUnRMeta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/me/rewards/vplus.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93cALKLZbnMwMeta?.name ?? "me-special-slug",
    path: _91slug_93cALKLZbnMwMeta?.path ?? "/me/special/:slug()",
    meta: _91slug_93cALKLZbnMwMeta || {},
    alias: _91slug_93cALKLZbnMwMeta?.alias || [],
    redirect: _91slug_93cALKLZbnMwMeta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/me/special/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91id_93NBgcJWx7DOMeta?.name ?? "me-voucher-id",
    path: _91id_93NBgcJWx7DOMeta?.path ?? "/me/voucher/:id()",
    meta: _91id_93NBgcJWx7DOMeta || {},
    alias: _91id_93NBgcJWx7DOMeta?.alias || [],
    redirect: _91id_93NBgcJWx7DOMeta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/me/voucher/[id].vue").then(m => m.default || m)
  },
  {
    name: indexFDeKOvF6WnMeta?.name ?? "me-voucher",
    path: indexFDeKOvF6WnMeta?.path ?? "/me/voucher",
    meta: indexFDeKOvF6WnMeta || {},
    alias: indexFDeKOvF6WnMeta?.alias || [],
    redirect: indexFDeKOvF6WnMeta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/me/voucher/index.vue").then(m => m.default || m)
  },
  {
    name: badge40U9cXocZ6Meta?.name ?? "notification-badge",
    path: badge40U9cXocZ6Meta?.path ?? "/notification/badge",
    meta: badge40U9cXocZ6Meta || {},
    alias: badge40U9cXocZ6Meta?.alias || [],
    redirect: badge40U9cXocZ6Meta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/notification/badge.vue").then(m => m.default || m)
  },
  {
    name: birthdayWVHuINs5IoMeta?.name ?? "notification-birthday",
    path: birthdayWVHuINs5IoMeta?.path ?? "/notification/birthday",
    meta: birthdayWVHuINs5IoMeta || {},
    alias: birthdayWVHuINs5IoMeta?.alias || [],
    redirect: birthdayWVHuINs5IoMeta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/viennazuckerl/pages/notification/birthday.vue").then(m => m.default || m)
  },
  {
    name: birthdayweekrVTDctBKjjMeta?.name ?? "notification-birthdayweek",
    path: birthdayweekrVTDctBKjjMeta?.path ?? "/notification/birthdayweek",
    meta: birthdayweekrVTDctBKjjMeta || {},
    alias: birthdayweekrVTDctBKjjMeta?.alias || [],
    redirect: birthdayweekrVTDctBKjjMeta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/viennazuckerl/pages/notification/birthdayweek.vue").then(m => m.default || m)
  },
  {
    name: christmasjxQt4plJyAMeta?.name ?? "notification-christmas",
    path: christmasjxQt4plJyAMeta?.path ?? "/notification/christmas",
    meta: christmasjxQt4plJyAMeta || {},
    alias: christmasjxQt4plJyAMeta?.alias || [],
    redirect: christmasjxQt4plJyAMeta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/viennazuckerl/pages/notification/christmas.vue").then(m => m.default || m)
  },
  {
    name: _91id_93fnPmr5Qy7iMeta?.name ?? "notification-detail-id",
    path: _91id_93fnPmr5Qy7iMeta?.path ?? "/notification/detail/:id()",
    meta: _91id_93fnPmr5Qy7iMeta || {},
    alias: _91id_93fnPmr5Qy7iMeta?.alias || [],
    redirect: _91id_93fnPmr5Qy7iMeta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/notification/detail/[id].vue").then(m => m.default || m)
  },
  {
    name: levelupK5DNuwhbYxMeta?.name ?? "notification-levelup",
    path: levelupK5DNuwhbYxMeta?.path ?? "/notification/levelup",
    meta: levelupK5DNuwhbYxMeta || {},
    alias: levelupK5DNuwhbYxMeta?.alias || [],
    redirect: levelupK5DNuwhbYxMeta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/notification/levelup.vue").then(m => m.default || m)
  },
  {
    name: profileRqRR8giDguMeta?.name ?? "notification-profile",
    path: profileRqRR8giDguMeta?.path ?? "/notification/profile",
    meta: profileRqRR8giDguMeta || {},
    alias: profileRqRR8giDguMeta?.alias || [],
    redirect: profileRqRR8giDguMeta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/notification/profile.vue").then(m => m.default || m)
  },
  {
    name: registerQdECHfPoBAMeta?.name ?? "notification-register",
    path: registerQdECHfPoBAMeta?.path ?? "/notification/register",
    meta: registerQdECHfPoBAMeta || {},
    alias: registerQdECHfPoBAMeta?.alias || [],
    redirect: registerQdECHfPoBAMeta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/notification/register.vue").then(m => m.default || m)
  },
  {
    name: specialday8ttKaMLvxcMeta?.name ?? "notification-specialday",
    path: specialday8ttKaMLvxcMeta?.path ?? "/notification/specialday",
    meta: specialday8ttKaMLvxcMeta || {},
    alias: specialday8ttKaMLvxcMeta?.alias || [],
    redirect: specialday8ttKaMLvxcMeta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/notification/specialday.vue").then(m => m.default || m)
  },
  {
    name: openhAQE9JNUr5Meta?.name ?? "open",
    path: openhAQE9JNUr5Meta?.path ?? "/open",
    meta: openhAQE9JNUr5Meta || {},
    alias: openhAQE9JNUr5Meta?.alias || [],
    redirect: openhAQE9JNUr5Meta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/viennazuckerl/pages/open.vue").then(m => m.default || m)
  },
  {
    name: _91id_93CZGER3ORbhMeta?.name ?? "preview-type-id",
    path: _91id_93CZGER3ORbhMeta?.path ?? "/preview/:type()/:id()",
    meta: _91id_93CZGER3ORbhMeta || {},
    alias: _91id_93CZGER3ORbhMeta?.alias || [],
    redirect: _91id_93CZGER3ORbhMeta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/preview/[type]/[id].vue").then(m => m.default || m)
  },
  {
    name: popups635NIisNNMeta?.name ?? "preview-notifications-popup",
    path: popups635NIisNNMeta?.path ?? "/preview/notifications/popup",
    meta: popups635NIisNNMeta || {},
    alias: popups635NIisNNMeta?.alias || [],
    redirect: popups635NIisNNMeta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/preview/notifications/popup.vue").then(m => m.default || m)
  },
  {
    name: detail1S1wAgUjh4Meta?.name ?? "preview-rewards-detail",
    path: detail1S1wAgUjh4Meta?.path ?? "/preview/rewards/detail",
    meta: detail1S1wAgUjh4Meta || {},
    alias: detail1S1wAgUjh4Meta?.alias || [],
    redirect: detail1S1wAgUjh4Meta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/preview/rewards/detail.vue").then(m => m.default || m)
  },
  {
    name: largecards7amSsDUKGMeta?.name ?? "preview-rewards-largecard",
    path: largecards7amSsDUKGMeta?.path ?? "/preview/rewards/largecard",
    meta: largecards7amSsDUKGMeta || {},
    alias: largecards7amSsDUKGMeta?.alias || [],
    redirect: largecards7amSsDUKGMeta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/preview/rewards/largecard.vue").then(m => m.default || m)
  },
  {
    name: smallcardCQZ8SCq76cMeta?.name ?? "preview-rewards-smallcard",
    path: smallcardCQZ8SCq76cMeta?.path ?? "/preview/rewards/smallcard",
    meta: smallcardCQZ8SCq76cMeta || {},
    alias: smallcardCQZ8SCq76cMeta?.alias || [],
    redirect: smallcardCQZ8SCq76cMeta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/preview/rewards/smallcard.vue").then(m => m.default || m)
  },
  {
    name: specialfbrrOlIuJZMeta?.name ?? "preview-specials-special",
    path: specialfbrrOlIuJZMeta?.path ?? "/preview/specials/special",
    meta: specialfbrrOlIuJZMeta || {},
    alias: specialfbrrOlIuJZMeta?.alias || [],
    redirect: specialfbrrOlIuJZMeta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/preview/specials/special.vue").then(m => m.default || m)
  },
  {
    name: mainrt2YkBRtaJMeta?.name ?? "widget-main",
    path: mainrt2YkBRtaJMeta?.path ?? "/widget/main",
    meta: mainrt2YkBRtaJMeta || {},
    alias: mainrt2YkBRtaJMeta?.alias || [],
    redirect: mainrt2YkBRtaJMeta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/widget/main.vue").then(m => m.default || m)
  },
  {
    name: index511YbLBD2XMeta?.name ?? "widget-post-id",
    path: index511YbLBD2XMeta?.path ?? "/widget/post/:id()",
    meta: index511YbLBD2XMeta || {},
    alias: index511YbLBD2XMeta?.alias || [],
    redirect: index511YbLBD2XMeta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/widget/post/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: prizewheelAxqEheSxjSMeta?.name ?? "widget-prizewheel",
    path: prizewheelAxqEheSxjSMeta?.path ?? "/widget/prizewheel",
    meta: prizewheelAxqEheSxjSMeta || {},
    alias: prizewheelAxqEheSxjSMeta?.alias || [],
    redirect: prizewheelAxqEheSxjSMeta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/widget/prizewheel.vue").then(m => m.default || m)
  },
  {
    name: profilesFLEdE09kxMeta?.name ?? "widget-profile",
    path: profilesFLEdE09kxMeta?.path ?? "/widget/profile",
    meta: profilesFLEdE09kxMeta || {},
    alias: profilesFLEdE09kxMeta?.alias || [],
    redirect: profilesFLEdE09kxMeta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/widget/profile.vue").then(m => m.default || m)
  },
  {
    name: quiz1tagG422sQMeta?.name ?? "widget-quiz",
    path: quiz1tagG422sQMeta?.path ?? "/widget/quiz",
    meta: quiz1tagG422sQMeta || {},
    alias: quiz1tagG422sQMeta?.alias || [],
    redirect: quiz1tagG422sQMeta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/widget/quiz.vue").then(m => m.default || m)
  },
  {
    name: _91id_93MX8lNq7cRPMeta?.name ?? "widget-reward-id",
    path: _91id_93MX8lNq7cRPMeta?.path ?? "/widget/reward/:id()",
    meta: _91id_93MX8lNq7cRPMeta || {},
    alias: _91id_93MX8lNq7cRPMeta?.alias || [],
    redirect: _91id_93MX8lNq7cRPMeta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/widget/reward/[id].vue").then(m => m.default || m)
  },
  {
    name: indexDlx2YgmdRpMeta?.name ?? "widget-reward",
    path: indexDlx2YgmdRpMeta?.path ?? "/widget/reward",
    meta: indexDlx2YgmdRpMeta || {},
    alias: indexDlx2YgmdRpMeta?.alias || [],
    redirect: indexDlx2YgmdRpMeta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/widget/reward/index.vue").then(m => m.default || m)
  },
  {
    name: indexkhBsH2GzGwMeta?.name ?? "widget-special",
    path: indexkhBsH2GzGwMeta?.path ?? "/widget/special",
    meta: indexkhBsH2GzGwMeta || {},
    alias: indexkhBsH2GzGwMeta?.alias || [],
    redirect: indexkhBsH2GzGwMeta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/widget/special/index.vue").then(m => m.default || m)
  },
  {
    name: simplepLY6eXLBuRMeta?.name ?? "widget-special-simple",
    path: simplepLY6eXLBuRMeta?.path ?? "/widget/special/simple",
    meta: simplepLY6eXLBuRMeta || {},
    alias: simplepLY6eXLBuRMeta?.alias || [],
    redirect: simplepLY6eXLBuRMeta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/widget/special/simple.vue").then(m => m.default || m)
  },
  {
    name: uservNQ8WbQBxGMeta?.name ?? "widget-user",
    path: uservNQ8WbQBxGMeta?.path ?? "/widget/user",
    meta: uservNQ8WbQBxGMeta || {},
    alias: uservNQ8WbQBxGMeta?.alias || [],
    redirect: uservNQ8WbQBxGMeta?.redirect,
    component: () => import("/builds/DEVV/russmedia-frontend-nuxt3/apps/tee/laendlepunkte/pages/widget/user.vue").then(m => m.default || m)
  }
]